<template>
    <div class="footer-wrapper">
        <footer class="footer">
            <div class="container">
                <div class="d-flex">
                    <div class="vertical-wrapper d-flex flex-row align-start">
                        <div class="footer-logo-wrapper d-flex flex-column align-center">
                            <nuxt-link to="/" class="footer-logo-parent">
                                <nuxt-img
                                    src="/public/img/logo_light.svg"
                                    alt="0x4447, LLC"
                                    class="footer-logo"
                                    format="webp"
                                    width="150"
                                    height="auto"
                                />
                            </nuxt-link>
                            <a href="https://www.linkedin.com/company/zer0x4447/" target="_blank" rel="noopener noreferrer" class="no-icon">
                                <img src="/public/img/social/linkedin.svg" alt="LinkedIn" width="24" height="24">
                            </a>
                        </div>
                        <div class="footer-text-wrapper">
                            <p class="footer-text mb-0">
                                Copyright &copy; Since 2016, 0x4447™ LLC.<br>All rights reserved.
                            </p>
                        </div>
                    </div>

                    <v-row>
                        <FooterSection title="RESOURCES" :items="resourceList" />
                        <FooterSection title="PRODUCTS" :items="productList" />
                        <FooterSection title="ABOUT" :items="aboutPageList" />
                        <FooterSection title="PROGRAMS" :items="programList" />
                    </v-row>
                    <!-- / .row -->
                </div>
            </div>
            <!-- / .container -->
        </footer>
    </div>
</template>

<script>
import FooterSection from '~/components/common/footer-section';

const DOCUMENTATION_PAGE_LINK = 'product-item-documentation-version';

export default {
    name: 'TheFooter',
    components: { FooterSection },
    computed: {
        documentationLink() {
            return this.$route.params.item
                ? DOCUMENTATION_PAGE_LINK
                : 'documentation';
        },
        resourceList() {
            const pages = [
                {
                    link: 'training',
                    name: 'Training',
                    tagline: this.$store.getters.getPageTagline('training'),
                    imgPath: this.$store.getters.getPageIcon('training')
                },
                {
                    link: 'support',
                    name: 'Support',
                    tagline: this.$store.getters.getPageTagline('support'),
                    imgPath: this.$store.getters.getPageIcon('support')
                },
                {
                    link: 'feedback',
                    name: 'Feedback',
                    tagline: this.$store.getters.getPageTagline('feedback'),
                    imgPath: this.$store.getters.getPageIcon('feedback')
                },
                {
                    link: 'together',
                    name: 'Together',
                    tagline: this.$store.getters.getPageTagline('together'),
                    imgPath: this.$store.getters.getPageIcon('together')
                },
                {

                    name: 'Sales',
                    link: 'sales',
                    tagline: this.$store.getters.getPageTagline('sales'),
                    imgPath: this.$store.getters.getPageIcon('sales')
                },
                {
                    link: 'refund',
                    name: 'Refund',
                    tagline: this.$store.getters.getPageTagline('refund'),
                    imgPath: this.$store.getters.getPageIcon('refund')

                },
                {
                    link: this.documentationLink,
                    name: 'Documentation',
                    tagline: 'product documentation',
                    imgPath: '/public/img/footer_icons/documentation.png'
                }
            ];

            return pages.map(resource => ({
                ...resource,
                to: this.getSubPageLink(resource.link)
            }));
        },
        productList() {
            const products = this.$store.state.products
                .filter(item => item.link !== this.$route.params.item)
                .sort((a, b) => {
                    return a.name.length - b.name.length;
                });

            return products.map(product => ({
                ...product,
                to: { name: 'product-item', params: { item: product.link } }
            }));
        },
        aboutPageList() {
            return [
                {
                    name: 'Contact',
                    to: this.getSubPageLink('contact'),
                    tagline: this.$store.getters.getPageTagline('contact'),
                    imgPath: this.$store.getters.getPageIcon('contact')
                },
                {
                    name: 'Company',
                    href: 'https://0x4447.com',
                    tagline: 'Headquarters',
                    imgPath: '/public/img/footer_icons/company.png'
                },
                {
                    name: 'Privacy Policy',
                    to: this.getSubPageLink('privacy-policy'),
                    tagline: this.$store.getters.getPageTagline('privacy_policy'),
                    imgPath: this.$store.getters.getPageIcon('privacy_policy')
                },
                {
                    name: 'Terms of Service',
                    to: this.getSubPageLink('terms-of-service'),
                    tagline: this.$store.getters.getPageTagline('terms_of_service'),
                    imgPath: this.$store.getters.getPageIcon('terms_of_service')
                }
            ];
        },
        programList () {
            return [
                {
                    name: 'Reseller Program',
                    to: this.getSubPageLink('reseller-program'),
                    tagline: this.$store.getters.getPageTagline('reseller_program'),
                    imgPath: this.$store.getters.getPageIcon('reseller_program')
                },
                {
                    name: 'Evaluation Program',
                    to: this.getSubPageLink('evaluation-program'),
                    tagline: this.$store.getters.getPageTagline('evaluation_program'),
                    imgPath: this.$store.getters.getPageIcon('evaluation_program')
                }
            ];
        }
    },
    methods: {
        getSubPageLink(name) {
            const nuxtLink = { name };

            if (name === DOCUMENTATION_PAGE_LINK) {
                nuxtLink.params = {
                    item: this.$route.params.item,
                    version: this.$store.getters.activeLatestDocVersion
                };
            }
            if (process.client) {
                if (this.$route.params.item) {
                    sessionStorage.setItem('prevProductPageName', this.$route.params.item);
                }
            }
            return nuxtLink;
        }
    }
};
</script>

<style lang="scss" src="./footer.scss" />
