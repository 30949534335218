<template>
    <li class="footer-list__item">
        <v-hover v-slot="{ hover }">
            <component
                :is="item.to ? 'nuxt-link' : 'a'"
                class="footer-list__item-link"
                v-bind="linkAttributes"
            >
                <IconViewer
                    v-if="item.imgPath"
                    :src="item.imgPath"
                    :alt="item.name"
                    :size="20"
                    class="footer-list__item-link-ico"
                />
                <div class="footer-list__item-text">
                    <span>{{ item.name }}</span>
                    <v-scroll-x-transition origin="top left 0">
                        <div v-if="hover" class="tag-hover">
                            {{ item.tag || item.tagline }}
                        </div>
                    </v-scroll-x-transition>
                </div>
            </component>
        </v-hover>
    </li>
</template>

<script>
import IconViewer from '~/components/common/icon-viewer';

export default {
    name: 'FooterListItem',
    components: { IconViewer },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        linkAttributes() {
            return this.item.to ? { to: this.item.to } : { href: this.item.href };
        }
    }
};
</script>
