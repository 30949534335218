<template>
    <div class="col-12 col-md-3 col-lg-3 py-0">
        <!-- Heading -->
        <component :is="hTag" class="footer-list__title">
            {{ title }}
        </component>

        <!-- List -->
        <ul class="list-unstyled">
            <FooterListItem
                v-for="(item, $itemIndex) in sortedItems"
                :key="$itemIndex"
                :item="item"
            />
        </ul>
    </div>
</template>

<script>
import FooterListItem from './footer-list-item';

export default {
    name: 'FooterSection',
    components: { FooterListItem },
    props: {
        title: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        sortedItems() {
            return [...this.items].sort((a, b) => a.name.length - b.name.length || a.name < b.name);
        },
        hTag() {
            const { name: routeName } = this.$route;
            if (routeName.startsWith('product-item') || routeName === 'index') {
                return 'h6';
            }

            return 'h3';
        }
    }
};
</script>
