<template>
    <nuxt-img
        :src="src"
        :alt="alt"
        :width="size"
        :height="size"
        loading="lazy"
        format="webp"
    />
</template>

<script>
export default {
    name: 'IconViewer',
    props: {
        size: {
            type: Number,
            default: 36
        },
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: ''
        }
    }
};
</script>
